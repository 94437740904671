<template>
    <AppLayout>
        <template #navigation>
            <div class="design-view__aside">
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            markdown="themes"
                            external-link="https://www.surveylegend.com/user-guide/designing-surveys/"
                        />
                        <span>Themes</span>
                    </RowComponent>
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <InputComponent
                        v-model="search"
                        class="input-component--search"
                        placeholder="Search..."
                    >
                        <template #prepend>
                            <svg
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </template>
                    </InputComponent>
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <SelectComponent
                        selected="Sort by name"
                        :options="[{ label: 'Sort by name' }, { label: 'Sort by category' }]"
                    />
                    <ButtonComponent
                        type="info"
                        :class="['button-component__sort', { 'is-ascending': sortIsAscending }]"
                        @click="toggleSortAscending"
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M19 10H5M21 6H3M17 14H7M15 18H9" />
                        </svg>
                    </ButtonComponent>
                </RowComponent>
                <ul class="design-view__themes">
                    <li
                        v-for="(theme, index) in computedThemes"
                        :key="index"
                        :class="[
                            'design-view__theme',
                            {
                                'is-active':
                                    survey.theme &&
                                    'theme-' + theme.icon.split('.')[0] === survey.theme.themeId
                            }
                        ]"
                        @click="handleThemeSelect(theme)"
                    >
                        <img class="design-view__theme__image" :src="getThemeImageUrl(theme)" />
                        <span>{{ theme.label }}</span>
                    </li>
                </ul>
            </div>
        </template>
        <div class="design-view">
            <div class="design-view__preview">
                <TooltipComponent width="auto" placement="left">
                    <template #tooltip>
                        <span>Preview</span>
                    </template>
                    <ButtonComponent
                        type="info"
                        @mouseenter="visiblePreviewPopover = true"
                        @mouseleave="visiblePreviewPopover = false"
                        @click="handlePreview"
                        ><svg width="16" height="16" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path
                                fill-rule="evenodd"
                                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </ButtonComponent>
                </TooltipComponent>
            </div>
        </div>
        <template #controls>
            <div class="design-view__aside">
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent width="331px" markdown="mood" />
                        <span>Mood</span>
                    </RowComponent>
                </RowComponent>
                <RowComponent>
                    <RadioGroupComponent
                        v-model="survey.themeMood"
                        @change="handleChange($event, 'themeMood')"
                    >
                        <RadioComponent value="theme-classic">Classic</RadioComponent>
                        <RadioComponent value="theme-light">Light</RadioComponent>
                        <RadioComponent value="theme-dark">Dark</RadioComponent>
                    </RadioGroupComponent>
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            markdown="hide-overlay"
                            external-link="https://www.surveylegend.com/user-guide/survey-colors-look-feel/"
                        />
                        <span>Hide overlay</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.noBackdrop"
                        prevent
                        @change="handleChange($event, 'noBackdrop')"
                        short
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            markdown="color-palette"
                            external-link="https://www.surveylegend.com/user-guide/survey-colors-look-feel/"
                        />
                        <span>Color palette</span>
                    </RowComponent>
                    <SelectComponent :options="computedPalettes" filterable>
                        <template #default="slotProps">
                            <div :class="['palette', slotProps.option.value]" />
                            <div>{{ slotProps.option.label }}</div>
                        </template>
                    </SelectComponent>
                </RowComponent>
                <DividerComponent />
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            markdown="typography"
                            external-link="https://www.surveylegend.com/user-guide/survey-fonts-typography/"
                        />
                        <span>Typography</span>
                    </RowComponent>
                    <SelectComponent :options="computedTypography" filterable>
                        <template #default="slotProps">
                            <span :class="slotProps.option.value">{{
                                slotProps.option.label
                            }}</span>
                        </template>
                    </SelectComponent>
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            markdown="font-size"
                            external-link="https://www.surveylegend.com/user-guide/survey-fonts-typography/"
                        />
                        <span>Font size</span>
                    </RowComponent>
                </RowComponent>
                <RowComponent>
                    <RadioGroupComponent
                        v-model="survey.themeFontSize"
                        @change="handleChange($event, 'themeFontSize')"
                    >
                        <RadioComponent value="font-size-small">Small</RadioComponent>
                        <RadioComponent value="font-size-medium">Medium</RadioComponent>
                        <RadioComponent value="font-size-large">Large</RadioComponent>
                    </RadioGroupComponent>
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            placement="top-start"
                            markdown="right-to-left"
                            external-link="https://www.surveylegend.com/user-guide/create-surveys-right-left-languages/"
                        />
                        <span>RTL (Right to Left)</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.rightToLeft"
                        prevent
                        @change="handleChange($event, 'rightToLeft')"
                        short
                    />
                </RowComponent>
                <DividerComponent />
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            placement="top-start"
                            markdown="blur-background"
                            external-link="https://www.surveylegend.com/user-guide/survey-colors-look-feel/"
                        />
                        <span>Blur background</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.blurBackground"
                        prevent
                        @change="handleChange($event, 'blurBackground')"
                        short
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            placement="top-start"
                            markdown="background"
                            external-link="https://www.surveylegend.com/user-guide/survey-backgrounds/"
                        />
                        <span>Background</span>
                    </RowComponent>
                    <ButtonComponent type="primary" class="button-component__icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <span>Upload</span>
                    </ButtonComponent>
                </RowComponent>
            </div>
        </template>
    </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'

import { db } from '@/firebase'
import palettes from '@/assets/json/palettes'
import fonts from '@/assets/json/fonts'
import themes from '@/assets/json/themes'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'Design',

    metaInfo: {
        title: 'Design'
    },

    components: {
        AppLayout
    },

    data() {
        return {
            palettes,
            fonts,
            themes,
            survey: {},
            search: '',
            sortBy: 'name',
            sortIsAscending: false,
            visiblePreviewPopover: false
        }
    },

    computed: {
        computedThemes() {
            return this.themes
                .filter((theme) => {
                    return (
                        this.search.length === 0 ||
                        theme.category.toLowerCase().includes(this.search.toLowerCase()) ||
                        theme.label.toLowerCase().includes(this.search.toLowerCase()) ||
                        theme.tags.join(' ').toLowerCase().includes(this.search.toLowerCase())
                    )
                })
                .slice(0)
                .sort((a, b) => {
                    const first = this.sortBy === 'category' ? a.category : a.label
                    const second = this.sortBy === 'category' ? b.category : b.label

                    return this.sortIsAscending
                        ? second.localeCompare(first)
                        : first.localeCompare(second)
                })
        },

        computedPalettes() {
            return palettes.map((palette) => {
                return {
                    label: palette.name,
                    value: palette.className
                }
            })
        },

        computedTypography() {
            return fonts.map((font) => {
                return {
                    label: font.name,
                    value: font.fontNameStyle
                }
            })
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
            }
        }
    },

    methods: {
        getThemeImageUrl(theme) {
            return `${process.env.VUE_APP_CDN_URL}/img/thumbs/${theme.category}/${theme.icon}`
        },

        toggleSortAscending() {
            this.sortIsAscending = !this.sortIsAscending
        },

        handleThemeSelect(theme) {
            theme.themeId = 'theme-' + theme.icon.split('.')[0]

            this.handleSave({ theme })
        },

        handlePreview() {
            this.$router.push({ path: 'preview' })
        },

        handleSave(data) {
            this.$firebaseRefs.survey.update(data)
        },

        async handleChange(event, toggle) {
            const value = event.target ? event.target.value : event.value

            let permission
            let hasPermission
            let name

            if (value) {
                switch (toggle) {
                    case 'iframeTransparentBackground':
                        permission = 'embedded-transparent-background'
                        hasPermission = this.permissions[permission]

                        break
                    case 'iframeNotifications':
                        permission = 'embedded-notifications'
                        hasPermission = this.permissions[permission]

                        break
                    case 'iframeNotificationsIncludeResponses':
                        name = 'include-response'

                        permission = 'embedded-notifications'
                        hasPermission = this.permissions[permission]

                        if (hasPermission > 0) {
                            const isConfirmed = await this.$refs[`warning-${name}`].open()

                            this.$refs[`warning-${name}`].close()

                            if (!isConfirmed) return
                        }

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (isConfirmed)
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            const data = {}

            data[toggle] = value

            this.handleSave(data)
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style scope lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap&text=OpenSans');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap&text=Sansita');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap&text=OpenSansCondensed');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap&text=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap&text=IndieFlower');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap&text=NotoSerif');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap&text=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap&text=Ubuntu');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap&text=Quicksand');
@import url('https://fonts.googleapis.com/css2?family=Play&display=swap&text=Play');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap&text=Orbitron');
@import url('https://fonts.googleapis.com/css2?family=Mitr&display=swap&text=Mitr');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap&text=Lora');
@import url('https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap&text=GravitasOne');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap&text=BreeSerif');
@import url('https://fonts.googleapis.com/css2?family=Sanchez&display=swap&text=Sanchezf');
@import url('https://fonts.googleapis.com/css2?family=Scope+One&display=swap&text=ScopeOne');
@import url('https://fonts.googleapis.com/css2?family=IM+Fell+DW+Pica&display=swap&text=IMFellDWPica');
@import url('https://fonts.googleapis.com/css2?family=Cutive&display=swap&text=Cutive');
@import url('https://fonts.googleapis.com/css2?family=Trocchi&display=swap&text=Trocchi');
@import url('https://fonts.googleapis.com/css2?family=BioRhyme&display=swap&text=BioRhyme');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright&display=swap&text=CormorantUpright&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo&display=swap&text=Baloo');
@import url('https://fonts.googleapis.com/css2?family=Griffy&display=swap&text=Griffy');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap&text=Comfortaa');
@import url('https://fonts.googleapis.com/css2?family=Overlock&display=swap&text=Overlock');
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap&text=ArimaMadurai');
@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap&text=LoveYaLikeASister');
@import url('https://fonts.googleapis.com/css2?family=Oregano&display=swap&text=Oregano');
@import url('https://fonts.googleapis.com/css2?family=Pompiere&display=swap&text=Pompiere');
@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap&text=Sniglet');
@import url('https://fonts.googleapis.com/css2?family=Glass+Antiqua&display=swap&text=GlassAntiqua');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap&text=AmaticSC');
@import url('https://fonts.googleapis.com/css2?family=Rochester&display=swap&text=Rochester');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap&text=ریم%20کوفی');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap&subset=arabic&text=قاهرة');
@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap&subset=arabic&text=تجوّل');
@import url('https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap&subset=arabic');
@import url('https://fonts.googleapis.com/css2?family=Changa&display=swap&text=چنگا');
@import url('https://fonts.googleapis.com/css2?family=Scheherazade&display=swap&text=شهرزاد');
@import url('https://fonts.googleapis.com/css2?family=Katibeh&display=swap&text=کتیبه');
@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap&subset=arabic&text=زارلاله');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa&display=swap&subset=arabic&text=عارف%20رقعه');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan&display=swap&subset=arabic&text=بالو%20بهایجان');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap&subset=arabic,cyrillic&text=المسيري');

.design-view {
    height: auto;

    min-height: 100vh;
    max-height: 100vh;

    overflow-y: auto;
}

.design-view__aside {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding: 16px;

    overflow-y: auto;

    > .row-component {
        color: #2c3e50;
        font-size: 14px;

        margin-bottom: 20px;
    }

    .radio-group-component {
        width: 100%;
    }

    .select-component {
        width: 100%;

        .select-component__option__inner {
            padding: 12px 18px;
        }

        .palette {
            width: 18px;
            height: 18px;

            margin-right: 10px;

            border-radius: 50%;
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
        }

        .palette-lava {
            background-image: linear-gradient(90deg, #fe7877 50%, #e66e6e 50%);
        }

        .palette-sunset {
            background-image: linear-gradient(90deg, #fb6a87 50%, #e0607a 50%);
        }

        .palette-heat {
            background-image: linear-gradient(90deg, #fc889f 50%, #e0798e 50%);
        }

        .palette-clay {
            background-image: linear-gradient(90deg, #fd925e 50%, #e68457 50%);
        }

        .palette-soil {
            background-image: linear-gradient(90deg, #fcb100 50%, #e6a100 50%);
        }

        .palette-desert {
            background-image: linear-gradient(90deg, #ffd670 50%, #e6bf65 50%);
        }

        .palette-sun {
            background-image: linear-gradient(90deg, #ffd670 50%, #fc0 50%);
        }

        .palette-wood {
            background-image: linear-gradient(90deg, #b3916f 50%, #997c5f 50%);
        }

        .palette-chocolate {
            background-image: linear-gradient(90deg, #542a26 50%, #40201d 50%);
        }

        .palette-leaf {
            background-image: linear-gradient(90deg, #2dcc70 50%, #29b362 50%);
        }

        .palette-sprout {
            background-image: linear-gradient(90deg, #9ff23a 50%, #8fd934 50%);
        }

        .palette-forest {
            background-image: linear-gradient(90deg, #88e18e 50%, #79c77f 50%);
        }

        .palette-lagoon {
            background-image: linear-gradient(90deg, #2acec6 50%, #25b3ab 50%);
        }

        .palette-lake {
            background-image: linear-gradient(90deg, #04e6f7 50%, #04cddb 50%);
        }

        .palette-turquoise {
            background-image: linear-gradient(90deg, #70ffd9 50%, #65e6c3 50%);
        }

        .palette-glacier {
            background-image: linear-gradient(90deg, #99e1ff 50%, #8acbe6 50%);
        }

        .palette-sky {
            background-image: linear-gradient(90deg, #59a8ff 50%, #007aff 50%);
        }

        .palette-sea {
            background-image: linear-gradient(90deg, #5fa9ff 50%, #579ae6 50%);
        }

        .palette-ocean {
            background-image: linear-gradient(90deg, #3498db 50%, #2980b9 50%);
        }

        .palette-twilight {
            background-image: linear-gradient(90deg, #c29eff 50%, #ae8ee6 50%);
        }

        .palette-ribbon {
            background-image: linear-gradient(90deg, #fb88ff 50%, #e07ce6 50%);
        }

        .palette-coal {
            background-image: linear-gradient(90deg, #34495e 50%, #2c3e50 50%);
        }

        .palette-night {
            background-image: linear-gradient(90deg, #394151 50%, #262b36 50%);
        }

        .palette-smoke {
            background-image: linear-gradient(90deg, #8293b9 50%, #707f9e 50%);
        }

        .palette-snow {
            background-image: linear-gradient(90deg, #f2f6fa 50%, #dadde0 50%);
        }

        .typography-name-Open-Sans {
            font-family: 'Open Sans', Helvetica, Arial, sans-serif;
        }

        .typography-name-Sansita {
            font-family: 'Sansita', sans-serif;
        }

        .typography-name-Open-Sans-Condensed {
            font-family: 'Open Sans Condensed', sans-serif;
        }

        .typography-name-Raleway {
            font-family: 'Raleway', sans-serif;
        }

        .typography-name-Indie-Flower {
            font-family: 'Indie Flower', cursive;
        }

        .typography-name-Noto-Serif {
            font-family: 'Noto Serif', serif;
        }

        .typography-name-Roboto {
            font-family: 'Roboto', sans-serif;
        }

        .typography-name-Ubuntu {
            font-family: 'Ubuntu', sans-serif;
        }

        .typography-name-Quicksand {
            font-family: 'Quicksand', sans-serif;
        }

        .typography-name-Play {
            font-family: 'Play', sans-serif;
        }

        .typography-name-Orbitron {
            font-family: 'Orbitron', sans-serif;
        }

        .typography-name-Mitr {
            font-family: 'Mitr', sans-serif;
        }

        .typography-name-Lora {
            font-family: 'Lora', serif;
        }

        .typography-name-Gravitas-One {
            font-family: 'Gravitas One', cursive;
        }

        .typography-name-Bree-Serif {
            font-family: 'Bree Serif', serif;
        }

        .typography-name-Sanchez {
            font-family: 'Sanchez', serif;
        }

        .typography-name-Scope-One {
            font-family: 'Scope One', serif;
        }

        .typography-name-IM-Fell-DW-Pica {
            font-family: 'IM Fell DW Pica', serif;
        }

        .typography-name-Cutive {
            font-family: 'Cutive', serif;
        }

        .typography-name-Trocchi {
            font-family: 'Trocchi', serif;
        }

        .typography-name-BioRhyme {
            font-family: 'BioRhyme', serif;
        }

        .typography-name-Cormorant-Upright {
            font-family: 'Cormorant Upright', serif;
        }

        .typography-name-Baloo {
            font-family: 'Baloo', cursive;
        }

        .typography-name-Griffy {
            font-family: 'Griffy', cursive;
        }

        .typography-name-Comfortaa {
            font-family: 'Comfortaa', cursive;
        }

        .typography-name-Overlock {
            font-family: 'Overlock', cursive;
        }

        .typography-name-Arima-Madurai {
            font-family: 'Arima Madurai', cursive;
        }

        .typography-name-Love-Ya-Like-A-Sister {
            font-family: 'Love Ya Like A Sister', cursive;
        }

        .typography-name-Oregano {
            font-family: 'Oregano', cursive;
        }

        .typography-name-Pompiere {
            font-family: 'Pompiere', cursive;
        }

        .typography-name-Sniglet {
            font-family: 'Sniglet', cursive;
        }

        .typography-name-Glass-Antiqua {
            font-family: 'Glass Antiqua', cursive;
        }

        .typography-name-Amatic-SC {
            font-family: 'Amatic SC', cursive;
        }

        .typography-name-Rochester {
            font-family: 'Rochester', cursive;
        }

        .typography-name-Reem-Kufi {
            font-family: 'Reem Kufi', sans-erif;
        }

        .typography-name-Cairo {
            font-family: 'Cairo', sans-serif;
        }

        .typography-name-Tajawal {
            font-family: 'Tajawal', sans-serif;
        }

        .typography-name-markazi-text {
            font-family: 'Markazi Text', serif;
        }

        .typography-name-Changa {
            font-family: 'Changa', sans-serif;
        }

        .typography-name-Scheherazade {
            font-family: 'Scheherazade', serif;
        }

        .typography-name-Katibeh {
            font-family: 'Katibeh', cursive;
        }

        .typography-name-Lalezar {
            font-family: 'Lalezar', cursive;
        }

        .typography-name-Aref-Ruqaa {
            font-family: 'Aref Ruqaa', serif;
        }

        .typography-name-Baloo-Bhaijaan {
            font-family: 'Baloo Bhaijaan', cursive;
        }

        .typography-name-El-Messiri {
            font-family: 'El Messiri', sans-serif;
        }
    }

    .input-component__prepend {
        display: flex;

        justify-content: center;
        align-items: center;

        color: #34495e;

        padding-left: 12px;
    }

    .button-component__sort {
        padding: 13px;
        margin-left: 10px;

        svg {
            transition: transform 0.2s ease;
        }

        &.is-ascending svg {
            transform: rotate(180deg);
        }
    }

    .button-component__icon {
        svg {
            margin-right: 8px;
        }
    }
}

.design-view__themes {
    flex-grow: 1;

    list-style-type: none;

    border: 1px solid #f3f3f3;
    border-radius: 4px;

    overflow-y: scroll;
}

.design-view__theme {
    display: flex;

    align-items: center;

    color: #2c3e50;
    font-size: 14px;

    padding: 10px 14px;

    cursor: pointer;

    &:hover {
        background-color: #f3f3f3;
    }

    &:first-child {
        border: none;
    }

    &:only-child {
        border-bottom: 1px solid #f3f3f3;
    }

    &.is-active {
        font-weight: 500;

        background-color: #f3f3f3;
        border-top: none;
        border-right: 4px solid orange;

        cursor: default;

        + .design-view__theme {
            border-top: none;
        }
    }
}

.design-view__theme__image {
    width: 32px;
    height: 32px;

    object-fit: cover;

    margin-right: 10px;

    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
}

.design-view__preview {
    position: absolute;

    top: 16px;
    right: 16px;

    .button-component {
        color: #34495e;

        padding: 12px;

        background-color: rgba(#fff, 0.85);

        &:hover {
            background-color: #fff;
        }
    }

    .popover-component__container {
        display: flex;

        justify-content: center;

        padding: 10px 12px;
    }
}
</style>
